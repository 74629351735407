import React from 'react'

import Matches from '@components/matches'
import Layout from '@components/layout'
import SEO from '@components/seo'

const NextMatch = () => {
  const title = 'Seuraava ottelu'

  return (
    <Layout>
      <SEO title={title} />
      <Matches title={title} isNextMatchPage />
    </Layout>
  )
}

export default NextMatch
